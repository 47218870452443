import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import KitIron from './components/KitIron';
import Video from './components/Video';
import Benefit from './components/Benefit';
import Form from './components/Form';
import Footer from './components/Footer';
import Agradecimento from './components/Agradecimento';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
        <KitIron />
        <Video />
        <Benefit />
        <Form />
        <Footer />
      </header>
    </div>
  );
}

function MainRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/agradecimento" element={<Agradecimento />} />
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  );
}

export default MainRouter;