import React from 'react';
import { Icon } from '@iconify/react'
import bg from '../img/backgroundmask.png'

const Agradecimento = () => {
  return (
    <>
      <div className="Agradecimento" >
        <h1 className='titleAgradecimento'>Agradecemos <br /> o seu interesse!</h1>
        <p className='subtitleAgradecimento'>Siga nossas redes sociais</p>

        <div className="agradecimentoContainer">
          <div className='agradecimentoCards'>
            <div>
              <Icon icon="mdi:instagram" className="iconify" />
            </div>
            <a
              href="https://www.instagram.com/m1motorsoficial" target="_blank"
              rel="noopener noreferrer"
            >
              <span>Instagram</span>
            </a>
          </div>

          <div className='agradecimentoCards'>
            <div>
              <Icon icon="mingcute:facebook-line" className="iconify" />
            </div>
            <a
              href="https://www.facebook.com/M1Motorscwb" target="_blank"
              rel="noopener noreferrer"
            >
              <span>Facebook</span>
            </a>
          </div>

          <div className='agradecimentoCards'>
            <div>
              <Icon icon="ic:outline-tiktok" className="iconify" />
            </div>
            <a
              href="https://www.tiktok.com/@m1motors" target="_blank"
              rel="noopener noreferrer"
            >
              <span>TikTok</span>
            </a>
          </div>
        </div>
      </div>
      <div className="bgImageContainer">
        <img src={bg} alt="Background Image" className="desktopImage"  />
      </div>

      <div>
          <h1 className='titleGrupo'>
            Fique por dentro de tudo que acontece no{' '}
            <a
              href="https://grupom1.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#ff3038', textDecoration: 'none' }}
            >
              Grupo M1
            </a>
          </h1>
        </div>
    </>
  );
};

export default Agradecimento;