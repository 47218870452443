import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import beneft from '../img/benefit.png';
import line from '../img/line.png';

const Form = () => {
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    cidade: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "telefone") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.startsWith("+55") ? value : `+55${value}`
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { nome, telefone, email, cidade } = formData;

    const data = {
      nome,
      telefone,
      email,
      cidade
    };

    try {
      const response = await fetch('https://automacao-n8n.px80eh.easypanel.host/webhook/typebot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        showAlert('Formulário enviado com Sucesso!  Aguarde um momento...', true);
        setFormData({ nome: '', telefone: '', email: '', cidade: '' });

        setTimeout(() => {
          console.log('Redirecionando para:', 'https://chatm1.grupom1.com.br/franquiasm1motors');
          window.location.href = 'https://chatm1.grupom1.com.br/franquiasm1motors';
        }, 2000);
      } else {
        showAlert('Houve um erro ao enviar o formulário.', false);
      }
    } catch (error) {
      console.error('Erro:', error);
      showAlert('Houve um erro ao enviar o formulário.', false);
    }
  };

  const showAlert = (message, isSuccess) => {
    const alertBox = document.getElementById("customAlert");
    const alertMessage = document.querySelector(".custom-alert-content p");
    const alertIcon = document.querySelector(".custom-alert-icon");

    alertBox.style.display = "flex";
    alertBox.style.backgroundColor = isSuccess ? "#d4edda" : "#f8d7da";
    alertMessage.textContent = message;
    alertMessage.style.color = isSuccess ? "#28a745" : "#dc3545";
    alertIcon.style.color = isSuccess ? "#28a745" : "#dc3545";

    const icon = isSuccess ? faCheckCircle : faTimesCircle;
    alertIcon.setAttribute('data-icon', icon.iconName);
  };

  const closeAlert = () => {
    document.getElementById("customAlert").style.display = "none";
  };

  return (
    <section className="form" id="formulario">
      <h2 className="formTitle">Quero ser um <span style={{ color: '#FF3038' }}>Franqueado</span></h2>
      <p className="formSub">
        Preencha o formulário abaixo para dar o primeiro <br />passo para a sua liberdade financeira!
      </p>
      <form className="formInputsContainer" onSubmit={handleSubmit}>
        <div>
          <h2>Nome</h2>
          <input
            type="text"
            name="nome"
            placeholder="Digite seu nome"
            value={formData.nome}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <h2>Telefone</h2>
          <input
            type="tel"
            name="telefone"
            placeholder="+55"
            value={formData.telefone}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <h2>Email</h2>
          <input
            type="email"
            name="email"
            placeholder="Digite seu email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <h2>Cidade</h2>
          <input
            type="text"
            name="cidade"
            placeholder="Digite sua cidade"
            value={formData.cidade}
            onChange={handleChange}
            required
          />
        </div>

        <div className="buttonContainerForm">
          <button type="submit" className="btns">
            <span className="ctaText">Avançar para atendimento</span>
          </button>
        </div>
      </form>

      <div id="customAlert" className="custom-alert">
        <div className="custom-alert-content">
          <FontAwesomeIcon icon={faCheckCircle} className="custom-alert-icon" />
          <p></p>
          <button onClick={closeAlert}>X</button>
        </div>
      </div>
    </section>
  );
};

export default Form;
