import React from 'react'
const Video = () => (
    <>
        <section className="video">
            <h2 className="videoTitle">Veja como a M1 Motors pode mudar sua vida</h2>
            <div className='borderVideo'>
                <iframe width="100%" height="694"
                    src="https://www.youtube.com/embed/MncbBJ6khzc?autoplay=1&rel=0"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>


            <h2 className="videoTitle">Aumente Seus Ganhos: Lucre
                <span style={{ color: '#FF3038' }}> R$5.000,00*</span>
            </h2>
            <div className="containerLucro">
                <div className='circleLucro'>
                    <span className='valueLucro'>R$20*</span>
                </div>
                <h1>POR CADA VEÍCULO<br /> ANUNCIADO</h1>

                <div className='circleLucro'>
                    <span className='valueLucro'>0,5%</span>
                </div>
                <h1>POR VENDA DE <br />VEÍCULO NO <br />NOSSO SITE</h1>

                <div className='circleLucro'>
                    <span className='valueLucro'>1,0%</span>
                </div>
                <h1>NA VENDA DE<br /> CONSÓRCIO</h1>

                <div className='circleLucro'>
                    <span className='valueLucro'>1,0%</span>
                </div>
                <h1>NO VALOR DO<br /> FINANCIAMENTO</h1>
            </div>
            <div style={{marginTop: '-7%'}}>
            <span className='spanLucro'>*Valores aproximados. *Sobre o valor do faturamento.</span>
            </div>

            <div className="buttonContainer">
                <a href="#formulario"
                    rel="noopener noreferrer"
                    className="btns"
                >
                    <span className="ctaText">Garanta Agora essa Oportunidade!</span>
                </a>
            </div>
        </section>
    </>
);

export default Video;