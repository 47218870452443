import React from 'react'
import work from '../img/homework.png'
import kit from '../img/kit.png'

const Benefit = () => (
    <section className="benefits" id="benefits">
        <div className="cardsContainer">
            <div className="card">
                <h2 className="kitTitle">Está Pronto para Transformar Sua Vida?</h2>
                <img src={work} alt="Man" />
                <p className="cardSubtitle">
                    A oportunidade de trabalhar de casa em um negócio lucrativo acaba
                    de ficar ainda mais acessível para você. Aproveite agora mesmo!
                </p>
            </div>

            <div className="card">
                <h2 className="kitTitle">Kit para o <br />Franqueado</h2>
                <img src={kit} alt="Kit" />
                <p className="cardSubtitle">
                    Kit Exclusivo para você começar sua jornada rumo à liberdade
                    financeira com segurança e sucesso garantido.
                </p>
            </div>
        </div>

        <div className="buttonContainer">
            <a href="#formulario"
                rel="noopener noreferrer"
                className="btns"
            >
                <span className="ctaText">Venha ser um Franqueado agora!</span>
            </a>
        </div>
    </section>
);

export default Benefit;