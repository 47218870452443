import React from 'react'
import logo from '../img/logom1motors.png'
import banner from '../img/bannerhouse.png'

const Header = () => (
    <>
        <header className="header">
            <div className="logoContainer">
                <img src={logo} alt="Logo" className="logo" />
            </div>
        </header>

        <section className="banner">
            <div className="bannerImageContainer">
                <img src={banner} alt="Banner" className="bannerImage" />
            </div>
            <div className="bannerText">
                <h1 className="IronTitle">Tenha um negócio de sucesso seja um</h1>
                <p className="IronText">Iron House</p>
                <div className="ctaWrapper">
                    <span className="ctaSecondaryText">Entre em contato</span>
                    <a href="#formulario"
                        rel="noopener noreferrer"
                        className="cta"
                    >
                        <span className="ctaText">Seja um franqueado</span>
                    </a>
                </div>
                <p className="pHeader">
                    Está pronto para faturar sem sair de casa? Trabalhe com
                    flexibilidade e a segurança de uma marca consolidada, com uma franquia
                    <span style={{ fontWeight: '800' }}> M1 Motors.</span>
                </p>
            </div>
            <div className="redLine"></div>
        </section>
    </>
);

export default Header;